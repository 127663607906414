import React, { useState, useEffect } from "react";
import { Container, Button , Row, Col } from "react-bootstrap";
import Image from "./basico/image";
import Modal3Pasos from "./modal3Pasos";
import carroNuevo from "../../images/nuevo-carro.png";

export default function BannerImage({alea, props}){

    const [ pasos, setPasos ] = useState({
        paso1: "Paso1",
        paso2: 0,
        paso3: 0,
    });
    
    const [ show, setShow ] = useState(false);
    const handleClose = () => {setShow(false); setPasos({...pasos, paso1: "Paso1", paso2: 0, paso3: 0})}

    return(
        <>
            <div >
                <ImagenBanner numAleatorio={alea}/>
            </div>

            {/* <Modal3Pasos show={show} handleClose={handleClose} pasos={pasos} setPasos={setPasos} props={props}/> */}
        </>
    )
}

function ImagenBanner ({numAleatorio}){

    return(
        <>
            <div className="d-md-block d-none">
                <Image
                    className="d-md-block d-none"
                    fileName={`banner-${numAleatorio}.jpg`}
                    alt="banner"
                    imgStyles={{height: "100%" }}
                    style={{height: "100%" }}
                />
            </div>
            <div className="d-sm-block d-md-none" style={{height: 'auto'}}>
                <Image
                    fileName={`banner-${numAleatorio}-movil.jpg`}
                    alt="banner"
                    imgStyles={{height: "100%" }}
                    style={{height: "100%" }}
                />
            </div>
        </>
)
}